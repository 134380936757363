import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './bar-chart.css';

const HorizontalBarChart = ({ data, color }) => {
  const chartRef = useRef(null);

  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTop, setTooltipTop] = useState('0px');
  const [tooltipLeft, setTooltipLeft] = useState('0px');

  useEffect(() => {
    if (!data || data.length === 0) return;

    d3.select(chartRef.current).selectAll("*").remove();

    const container = chartRef.current?.parentNode;
    if (container) {
      container.scrollTop = 0;
    }

    const margin = { top: 12, right: 20, bottom: 30, left: 120 };
    const containerWidth = chartRef.current.getBoundingClientRect().width;
    const chartWidth = containerWidth - margin.left - margin.right;
    const chartHeight = data.length * 25 - margin.bottom - margin.top;

    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', containerWidth)
      .attr('height', chartHeight + margin.top + margin.bottom)
      .append('g')
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const y = d3.scaleBand()
      .range([0, chartHeight])
      .domain(data.map(d => d.key))
      .padding(0.2);

    const x = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([0, chartWidth - 20]); // Reduce width to prevent x-axis overflow

    svg.append("g")
      .attr('class', 'y-axis')
      .call(d3.axisLeft(y));

    svg.append("g")
      .attr('class', 'x-axis')
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(d3.axisBottom(x).ticks(5));

    svg.selectAll('.y-axis path, .y-axis line, .x-axis path, .x-axis line').remove();
    svg.selectAll(".tick text").attr('class', 'chart_tick_text');

    svg.selectAll(".mybar")
      .data(data)
      .enter()
      .append("rect")
      .attr('class', 'mybar')
      .attr("fill", color || "#FDB022")
      .attr('rx', d => y.bandwidth() > 16 ? 8 : y.bandwidth() / 2)
      .attr("y", d => y(d.key))
      .attr("x", 0)
      .attr("width", d => x(d.value))
      .attr("height", d => Math.min(y.bandwidth(), 16))
      .on('mouseover', function(event, d) {
        svg.selectAll('rect').attr('fill-opacity', 0.6);
        d3.select(this).attr('fill-opacity', 1);

        svg.selectAll('.y-axis text').attr('fill-opacity', 0.6);
        svg.selectAll('.y-axis text')
          .filter((_, i) => data[i].key === d.key)
          .attr('fill-opacity', 1);

        setTooltipTitle(d.key);
        setTooltipValue(d.value);
        setTooltipVisible(true);
      })
      .on('mousemove', (event) => {
        setTooltipTop(`${event.clientY}px`);
        setTooltipLeft(`${event.clientX}px`);
      })
      .on('mouseout', function() {
        svg.selectAll('rect').attr('fill-opacity', 1);
        svg.selectAll('.y-axis text').attr('fill-opacity', 1);
        setTooltipVisible(false);
      });

  }, [data, color]);

  return (
    <div className="chart-container" style={{ position: 'relative', maxWidth: '100%' }}>
      <div ref={chartRef} className="chart" style={{ overflowY: 'auto', overflowX: 'hidden', height: '400px' }}></div>
      {tooltipVisible && (
        <div
          className="tooltip"
          style={{
            position: 'absolute',
            top: tooltipTop,
            left: tooltipLeft,
            background: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            padding: '8px',
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 10,
          }}
        >
          <div>{tooltipTitle}</div>
          <div>{tooltipValue}</div>
        </div>
      )}
    </div>
  );
};

export default HorizontalBarChart;
