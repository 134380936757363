import React, { useState, useContext, useEffect } from 'react';
import Section from '../section/section';
import LineChart from '../line-chart/line-chart';
import ChartCard from '../chart-card/chart-card';
import Context from '../../context/context';
import EstablishmentTypeToggle from '../establishment-type-toggle/establishment-type-toggle';
import { months } from '../../constants';

import './distribution-over-time.css';

const DistributionOverTime = () => {
  const {
    filteredReviews,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  } = useContext(Context);

  function computeVisitsOverTime() {
    const dateMap = filteredReviews.reduce((reviewAcc, review) => {
      if (reviewAcc[review.travel_date]) {
        reviewAcc[review.travel_date] += 1;
      } else {
        reviewAcc[review.travel_date] = 1;
      }
      return reviewAcc;
    }, {});
    return Object.keys(dateMap)
      .map((dateKey) => {
        return {
          date: new Date(dateKey),
          value: dateMap[dateKey],
        };
      })
      .sort((a, b) => {
        return a.date - b.date;
      });
  }

  function xTicksFormatter(value) {
    const options = { year: '2-digit', month: 'short' };
    return value.toLocaleString('en-US', options);
  }

  const [visitsOverTime, setVisitsOverTime] = useState(computeVisitsOverTime());

  useEffect(() => {
    setVisitsOverTime(computeVisitsOverTime());
  }, [filteredReviews]);

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  return (
    <Section title={<span>Number of reviews over time</span>}>
      <EstablishmentTypeToggle />
      <ChartCard>
        <LineChart
          id="number-of-visits"
          data={visitsOverTime}
          xTicksFormatter={xTicksFormatter}
          columns={{ date: 'Date', value: 'Value' }}
          fileName="reviews-over-time"
          filters={appliedFilters}
          title={'Number of reviews over time'}
        />
      </ChartCard>
    </Section>
  );
};

DistributionOverTime.propTypes = {};

DistributionOverTime.defaultProps = {};

export default DistributionOverTime;
