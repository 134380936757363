import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import Section from '../../components/section/section';
import MultyBarChart from '../../components/multy-bar-chart/multy-bar-chart';
import ChartCard from '../../components/chart-card/chart-card';
import MultyLineChart from '../../components/multy-line-chart/multy-line-chart';
import Context from '../../context/context';
import { months, ASPECT_TYPES, ESTABLISHMENT_TYPES } from '../../constants';
import AppliedFilters from '../../components/applied-filters/applied-filters';
import PageTitle from '../../components/page-title/page-title';

import './categories.css';

const establishmentTypes = ESTABLISHMENT_TYPES.map((p) => ({
  label: p,
  value: p,
}));

const Aspect = (props) => {
  const { aspect, value } = props;

  return (
    <div className="aspect">
      <div className="aspect-top-container">
        <div className="aspect-icon-container">
          <img
            className="aspect-icon"
            src={`/icons/${aspect.toLowerCase()}.png`}
            alt={aspect}
          />
        </div>
        <div className="aspect-name">{aspect}</div>
      </div>
      <div className="aspect-value">
        <div className="aspect-value-counter">{value}</div>
        Mentions
      </div>
    </div>
  );
};

const Categories = (props) => {
  const {
    filteredReviews,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
    updateSelectedCategory,
  } = useContext(Context);

  const [aspectsData, setAspectsData] = useState(computeAspectsData());
  const [sentimentTimeData, setSentimentTimeData] = useState(
    computeSentimentTimeData()
  );
  const [visitsOverYears, setVisitsOverYears] = useState(
    computeVisitsOverYears()
  );

  console.log(visitsOverYears);

  function computeAspectsData() {
    const aspectsDataMap = {};
    ASPECT_TYPES.forEach((aspect) => {
      aspectsDataMap[aspect] = 0;
    });

    filteredReviews.forEach((review) => {
      review.aspectsentiment_set.forEach((aspect) => {
        if (!selectedSentiment || aspect.label === selectedSentiment) {
          aspectsDataMap[aspect.aspect] += 1;
        }
      });
    });

    return aspectsDataMap;
  }

  function computeTotalAspects() {
    return Object.values(aspectsData).reduce((acc, value) => acc + value, 0);
  }

  function computeSentimentTimeData() {
    const sentimentArrays = filteredReviews.reduce(
      (sentimentAcc, review) => {
        const formatDate = new Date(review.date).toISOString().split('T')[0];

        review.aspectsentiment_set.forEach((aspect) => {
          // If no sentiment filter is selected, process all aspects
          if (!selectedSentiment || aspect.label === selectedSentiment) {
            // Ensure sentimentAcc[aspect.label.toLowerCase()] exists
            if (!sentimentAcc[aspect.label.toLowerCase()]) {
              sentimentAcc[aspect.label.toLowerCase()] = {};
            }

            // Safely increment the sentiment count for the given date
            sentimentAcc[aspect.label.toLowerCase()][formatDate] =
              (sentimentAcc[aspect.label.toLowerCase()][formatDate] || 0) + 1;
          }
        });

        return sentimentAcc;
      },
      { positive: {}, negative: {} }
    );

    // Merge the sentiment data into an array and sort by date
    const sentimentArrayMerged = [];
    Object.keys(sentimentArrays.positive).forEach((date) => {
      sentimentArrayMerged.push({
        key: new Date(date),
        group: 'Positive',
        value: sentimentArrays.positive[date],
      });
    });

    Object.keys(sentimentArrays.negative).forEach((date) => {
      sentimentArrayMerged.push({
        key: new Date(date),
        group: 'Negative',
        value: sentimentArrays.negative[date],
      });
    });

    return sentimentArrayMerged.sort((a, b) => a.key - b.key);
  }

  function xTicksFormatter(value) {
    const options = { year: '2-digit', month: 'short' };
    return value.toLocaleString('en-US', options);
  }

  function computeVisitsOverYears() {
    const yearMap = {};

    filteredReviews.forEach((review) => {
      const year = new Date(review.date).getFullYear();

      if (!yearMap[year]) {
        yearMap[year] = {
          reviews: 0,
          negative: 0,
          positive: 0,
        };
      }

      yearMap[year].reviews += 1;

      review.aspectsentiment_set.forEach((aspect) => {
        if (!selectedSentiment || aspect.label === selectedSentiment) {
          if (aspect.label === 'Positive') {
            yearMap[year].positive += 1;
          } else if (aspect.label === 'Negative') {
            yearMap[year].negative += 1;
          }
        }
      });
    });

    const res = Object.entries(yearMap).map(([year, yearData]) => {
      const result = { group: year };
      Object.entries(yearData).forEach(([key, value]) => {
        result[key] = value;
      });
      return result;
    });

    res.columns = ['group', 'positive', 'reviews', 'negative'];
    return res;
  }

  useEffect(() => {
    selectedCategory === null && updateSelectedCategory('Accommodation');
  }, [selectedCategory]);

  useEffect(() => {
    setSentimentTimeData(computeSentimentTimeData());
    setVisitsOverYears(computeVisitsOverYears());
    setAspectsData(computeAspectsData());
  }, [filteredReviews, selectedSentiment]);

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <PageTitle
            title="Categories Overview"
            subtitle="A comprehensive overview of aspects, sentiments, and reviews"
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <AppliedFilters />
        </Col>
      </Row>
      <h2 className="overview-header">Overview</h2>
      <Row className="overview-block" gutter={[24, 24]}>
        {ASPECT_TYPES.map((aspect) => {
          return (
            <Col key={aspect} span={6}>
              <Aspect aspect={aspect} value={aspectsData[aspect]} />
            </Col>
          );
        })}
        <Col span={6}>
          <Aspect aspect="Total" value={computeTotalAspects()} />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="category-selector">
            <h2 className="category-selector-title">Categories /</h2>
            <Select
              className="category-selector-select"
              mode="single"
              variant="borderless"
              value={selectedCategory}
              onChange={updateSelectedCategory}
              options={establishmentTypes}
            />
          </div>
          <Section
            title={
              <span>
                Trends in <b>Positive and Negative Sentiments</b> Over Time
              </span>
            }
          >
            <ChartCard>
              <MultyLineChart
                id="sentiment-over-time"
                xTicksFormatter={xTicksFormatter}
                data={sentimentTimeData}
                colors={['#6FCF97', '#EB5757']}
                columns={{ key: 'Date', group: 'Type', value: 'Value' }}
                fileName="sentiments-over-time"
                filters={appliedFilters}
                title={'Positive and negative sentiments over time'}
              />
            </ChartCard>
          </Section>
          <Section title={<span>Visitor Reviews Across Different Years</span>}>
            <ChartCard>
              <MultyBarChart
                id="visits-over-years"
                data={visitsOverYears}
                columns={{
                  group: 'Year',
                  reviews: 'Reviews',
                  positive: 'Positive',
                  negative: 'Negative',
                }}
                fileName="reviews-over-years"
                filters={appliedFilters}
                title={'Number of reviews based on years'}
              />
            </ChartCard>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Categories.propTypes = {};

Categories.defaultProps = {};

export default Categories;
