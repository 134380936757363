import React, { useContext, useState, useEffect, useRef } from 'react';
import { Col, Row, Space } from 'antd';
import Context from '../../context/context';
import Section from '../../components/section/section';
// import EstablishmentTypeToggle from '../../components/establishment-type-toggle/establishment-type-toggle';
import ChartCard from '../../components/chart-card/chart-card';
import LineChart from '../../components/line-chart/line-chart';
// import ScatterPlot from '../../components/scatter-plot/scatter-plot';
// import BoxPlot from '../../components/box-plot/box-plot';
import BarChart from '../../components/bar-chart/bar-chart';
import AppliedFilters from '../../components/applied-filters/applied-filters';
import PageTitle from '../../components/page-title/page-title';
import * as d3 from 'd3';
import Map from '../../components/map/map';
import { useLoaderData } from 'react-router-dom';
import './prices.css';

const Prices = () => {
  const {
    filteredEstablishments,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  } = useContext(Context);

  const geojsonData = useLoaderData();

  const computePricesOverTime = () => {
    const priceMap = filteredEstablishments.reduce(
      (priceAcc, establishment) => {
        if (
          establishment.price_set[0] &&
          establishment.price_set[0].price_range_avg
        ) {
          const dateKey = establishment.price_set[0].time_of_scrape_iso;
          if (priceAcc[dateKey]) {
            priceAcc[dateKey].push(establishment.price_set[0].price_range_avg);
          } else {
            priceAcc[dateKey] = [establishment.price_set[0].price_range_avg];
          }
        }
        return priceAcc;
      },
      {}
    );

    return Object.keys(priceMap)
      .map((dateKey) => {
        const prices = priceMap[dateKey];
        const averagePrice =
          prices.reduce((acc, price) => acc + price, 0) / prices.length;
        return {
          date: new Date(dateKey),
          value: averagePrice,
        };
      })
      .sort((a, b) => a.date - b.date);
  };

  const computePriceRanges = () => {
    const ranges = { '0-50': 0, '51-100': 0, '101-150': 0, '151+': 0 };
    filteredEstablishments.forEach((establishment) => {
      const price = establishment.price_set?.[0]?.price_range_avg || 0;
      if (price <= 50) ranges['0-50'] += 1;
      else if (price <= 100) ranges['51-100'] += 1;
      else if (price <= 150) ranges['101-150'] += 1;
      else ranges['151+'] += 1;
    });
    return Object.entries(ranges).map(([range, count]) => ({ range, count }));
  };

  const computeAveragePriceBySubcategory = () => {
    const groupedData = d3.group(filteredEstablishments, (d) => d.subcategory);

    return Array.from(groupedData, ([key, values]) => ({
      key,
      value: d3.mean(
        values.flatMap(
          (d) =>
            d.price_set
              .filter((p) => p.price_range_avg != null) // Exclude null/undefined prices
              .map((p) => p.price_range_avg) // Extract price_range_avg values
        )
      ),
    })).filter((item) => item.value !== undefined); // Remove objects with undefined value
  };

  function groupPricesByRankingRangeForSubcategory(data) {
    const groupedBySubcategory = d3.group(data, (d) => d.subcategory);

    const result = Array.from(groupedBySubcategory, ([subcategory, items]) => {
      const rankingRanges = {
        low: [],
        medium: [],
        high: [],
      };

      items.forEach((item) => {
        if (item.ranking_score != null) {
          const avgPrice = d3.mean(
            item.price_set
              .filter((p) => p.price_range_avg != null)
              .map((p) => p.price_range_avg)
          );

          if (avgPrice != null) {
            if (item.ranking_score < 3) {
              rankingRanges.low.push(avgPrice);
            } else if (item.ranking_score <= 4) {
              rankingRanges.medium.push(avgPrice);
            } else {
              rankingRanges.high.push(avgPrice);
            }
          }
        }
      });

      return {
        subcategory,
        rankingRanges: {
          low: d3.mean(rankingRanges.low) || null,
          medium: d3.mean(rankingRanges.medium) || null,
          high: d3.mean(rankingRanges.high) || null,
        },
      };
    });

    return result;
  }

  const [geodata, setGeodata] = useState(null);

  useEffect(() => {
    // Fetch GeoJSON file from the public folder
    const fetchGeoData = async () => {
      try {
        const response = await fetch('/geodata.geojson'); // Static file path
        if (!response.ok) {
          throw new Error('Failed to load GeoJSON');
        }
        const json = await response.json();
        setGeodata(json);
      } catch (error) {
        console.error('Error fetching GeoJSON:', error);
      }
    };

    fetchGeoData();
  }, []);

  function groupPricesByState(data) {
    const statePrices = {};

    data.forEach((item) => {
      const state = item.state;
      if (!state) return;
      const prices = item.price_set
        .filter((p) => p.price_range_avg != null) // Filter valid price entries
        .map((p) => p.price_range_avg); // Extract average prices

      if (!statePrices[state]) {
        statePrices[state] = [];
      }

      statePrices[state].push(...prices); // Collect all prices for the state
    });

    // Calculate average price for each state
    const result = Object.entries(statePrices).map(([state, prices]) => ({
      state,
      averagePrice: prices.length
        ? prices.reduce((sum, p) => sum + p, 0) / prices.length
        : null,
    }));

    return result;
  }

  const [prices, setPrices] = useState([]);
  const [pricesBySubcategory, setPricesBySubcategory] = useState([]);
  const [priceRanges, setPriceRanges] = useState([]);
  const [rankingRangePrices, setRankingRangePrices] = useState([]);
  const [pricesByState, setPricesByState] = useState([]);

  useEffect(() => {
    setPrices(computePricesOverTime());
    setPricesBySubcategory(computeAveragePriceBySubcategory());
    setPriceRanges(computePriceRanges());
    setRankingRangePrices(
      groupPricesByRankingRangeForSubcategory(filteredEstablishments)
    );
    setPricesByState(groupPricesByState(filteredEstablishments));
  }, [filteredEstablishments]);

  function xTicksFormatter(value) {
    return `${value.toLocaleString('default', {
      month: 'long',
    })} ${value.getFullYear()}`;
  }

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  const filteredPricesBySubcategory = pricesBySubcategory.filter(
    (item) => item.value !== undefined
  );

  const getValueFunction = (stateData) =>
    stateData ? stateData.averagePrice : null;

  const getColorsFunction = (data) => {
    if (!Array.isArray(data)) {
      return {
        trashold: [],
        range: d3.schemeBlues[5],
      };
    }

    const prices = data.map((d) => d.averagePrice).filter((p) => p != null);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    return {
      trashold: d3.range(minPrice, maxPrice, (maxPrice - minPrice) / 5),
      range: d3.schemeBlues[5],
    };
  };

  const getTooltipTextFunction = (stateData) => {
    if (!stateData || stateData.averagePrice == null) {
      return 'No data available';
    }
    return `$${stateData.averagePrice.toFixed(2)}`;
  };

  const getAveragePriceByState = pricesByState.reduce((acc, item) => {
    acc[item.state] = item;
    return acc;
  }, {});

  console.log(groupPricesByState(filteredEstablishments));

  return (
    <>
      <Row>
        <Col span={24}>
          <PageTitle title="Price Trends" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AppliedFilters />
        </Col>
      </Row>
      <Col gutter={[24, 24]} style={{ marginTop: '24px' }}>
        <Col span={24}>
          <Section title={<span>Average price by time</span>}>
            <ChartCard>
              <LineChart
                id="average-price-over-time"
                data={prices}
                xTicksFormatter={xTicksFormatter}
                columns={{ date: 'Date', value: 'Average Price' }}
                fileName="average-price-over-time"
                filters={appliedFilters}
                title={'Average Price Over Time'}
              />
            </ChartCard>
          </Section>
        </Col>
        <Col span={24}>
          <Section title={<span>Number of Establishments by Price Range</span>}>
            <ChartCard>
              <PriceRangeBarChart
                data={priceRanges}
                id="price-range-chart"
                // title="Number of Establishments by Price Range"
              />
            </ChartCard>
          </Section>
        </Col>
        <Col span={24}>
          <Section title={<span>Average price by subcategory</span>}>
            <ChartCard>
              <BarChart
                id="average-price-by-subcategory"
                data={filteredPricesBySubcategory}
                xTickTotated={true}
                height={300}
                columns={{ key: 'Subcategory', value: 'Average Price' }}
                fileName="average-price-by-subcategory"
                filters={appliedFilters}
                title={'Average Price by Subcategory'}
              />
            </ChartCard>
          </Section>
        </Col>
        {/* <Col span={24}>
          <Section title={<span>Price Distribution by Subcategory</span>}>
            <ChartCard>
              <BoxPlot
                id="price-distribution"
                data={filteredEstablishments}
                xLabel="Subcategory"
                yLabel="Price"
                fileName="price-distribution"
                filters={appliedFilters}
                title={'Price Distribution by Subcategory'}
              />
            </ChartCard>
          </Section>
        </Col> */}
        {/* <Col span={24}>
          <Section title={<span>Price vs. Rating</span>}>
            <ChartCard>
              <ScatterPlot
                id="price-vs-rating"
                data={filteredEstablishments
                  .map((item) => ({
                    x: item.rating,
                    y: item.price_set[0]
                      ? item.price_set[0].price_range_avg
                      : null,
                  }))
                  .filter((item) => item.y !== null)}
                xLabel="Rating"
                yLabel="Price"
                fileName="price-vs-rating"
                filters={appliedFilters}
                title={'Price vs. Rating'}
              />
            </ChartCard>
          </Section>
        </Col> */}

        <Col span={24}>
          <Section title={<span>Ranking Range Prices</span>}>
            <ChartCard>
              <AccommodationRankingChart
                data={groupPricesByRankingRangeForSubcategory(
                  filteredEstablishments
                )}
                id="ranking-range-prices"
              />
            </ChartCard>
          </Section>
        </Col>

        <Col span={24}>
          <Section title={<span>Average Price By Region</span>}>
            <ChartCard>
              <Map
                id="zanzibar-price-map"
                data={pricesByState.reduce((acc, item) => {
                  acc[item.state] = item;
                  return acc;
                }, {})}
                geodata={geojsonData}
                getValueFunction={getValueFunction}
                getColorsFunction={getColorsFunction}
                getTooltipTextFunction={getTooltipTextFunction}
                fileName="zanzibar-price-map"
                filters={appliedFilters}
                title="Average Price By Region"
                columns={{ state: 'Region', averagePrice: 'Average Price' }}
              />
            </ChartCard>
          </Section>
        </Col>
      </Col>
    </>
  );
};

export default Prices;

const PriceRangeBarChart = ({ data, id, title }) => {
  const margin = { top: 20, right: 30, bottom: 50, left: 60 };
  const width =
    document.getElementById(id)?.offsetWidth - margin.left - margin.right ||
    600;
  const height = 400 - margin.top - margin.bottom;

  const renderChart = () => {
    const container = d3.select(`#${id}`);
    container.selectAll('*').remove();

    const svg = container
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Tooltip
    const tooltip = d3
      .select('body')
      .append('div')
      .style('position', 'absolute')
      .style('background', '#37427A')
      .style('padding', '5px 12px')
      .style('border-radius', '6px')
      .style('pointer-events', 'none')
      .style('z-index', '9999')
      .style('opacity', 0)
      .style('display', 'flex')
      .style('flex-direction', 'column')
      .style('gap', '5px');

    // Scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.range))
      .range([0, width])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.count)])
      .nice()
      .range([height, 0]);

    // X-axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    // Y-axis
    svg.append('g').call(d3.axisLeft(y));

    // Bars
    const bars = svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.range))
      .attr('y', (d) => y(d.count))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d.count))
      .attr('fill', 'white')
      .on('mouseover', function (event, d) {
        // Dim all other bars
        bars.attr('fill', '#b0adbc');

        // Highlight the current bar
        d3.select(this).attr('fill', '#fff');

        // Show tooltip
        tooltip
          .style('opacity', 1)
          .html(
            `<div style="display: flex; font-size: 12px">
              <span style="margin-right: 10px; color: white">Range:</span>
              <span style="color: #FDB022">${d.range}</span>
            </div>
            <div style="display: flex; font-size: 12px">
              <span style="margin-right: 10px; color: white">Establishments:</span>
              <span style="color: #FDB022">${d.count}</span>
            </div>`
          )
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 20}px`);
      })
      .on('mousemove', (event) => {
        tooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 20}px`);
      })
      .on('mouseout', function () {
        // Restore all bars to white
        bars.attr('fill', 'white');

        // Hide tooltip
        tooltip.style('opacity', 0);
      });

    // Title
    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', -10)
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .text(title);
  };

  useEffect(() => {
    renderChart();
  }, [data]);

  return <div id={id} style={{ width: '100%' }}></div>;
};

// Ranking Range Prices Stacked Bar Chart

const AccommodationRankingChart = ({ data, id }) => {
  const margin = { top: 60, right: 30, bottom: 50, left: 60 };
  const width =
    document.getElementById(id)?.offsetWidth - margin.left - margin.right ||
    600;
  const height = 400 - margin.top - margin.bottom;

  const renderChart = () => {
    const container = d3.select(`#${id}`);
    container.selectAll('*').remove();

    const svg = container
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Tooltip
    const tooltip = d3
      .select('body')
      .append('div')
      .style('position', 'absolute')
      .style('background', '#37427A')
      .style('padding', '5px 12px')
      .style('border-radius', '6px')
      .style('pointer-events', 'none')
      .style('z-index', '9999')
      .style('opacity', 0)
      .style('display', 'flex')
      .style('flex-direction', 'column')
      .style('gap', '5px');

    // Process data for D3
    const processedData = data
      .filter(
        (item) =>
          item.rankingRanges.low !== null ||
          item.rankingRanges.medium !== null ||
          item.rankingRanges.high !== null
      )
      .map((item) => ({
        subcategory: item.subcategory,
        low: item.rankingRanges.low || 0,
        medium: item.rankingRanges.medium || 0,
        high: item.rankingRanges.high || 0,
      }));

    // Color scale
    const color = d3
      .scaleOrdinal()
      .domain(['low', 'medium', 'high'])
      .range(['#8884d8', '#82ca9d', '#ffc658']);

    // Scales
    const x = d3
      .scaleBand()
      .domain(processedData.map((d) => d.subcategory))
      .range([0, width])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(processedData, (d) => d.low + d.medium + d.high)])
      .range([height, 0]);

    // Stacked data
    const keys = ['low', 'medium', 'high'];
    const stackedData = d3.stack().keys(keys)(processedData);

    // Create bars
    const series = svg
      .selectAll('.series')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('fill', (d) => color(d.key));

    const bars = series
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.data.subcategory)) // X position based on subcategory
      .attr('y', (d) => y(d[1])) // Y position from top
      .attr('width', x.bandwidth()) // Bar width
      .attr('height', (d) => height - y(d[1] - d[0])) // Bar height
      .on('mouseover', function (event, d) {
        const key = d3.select(this.parentNode).datum().key;
        // Dim all other bars
        // bars.attr('fill', '#b0adbc');

        // Highlight the current bar
        // d3.select(this).attr('fill', '#fff');

        // Show tooltip
        tooltip
          .style('opacity', 1)
          .html(
            `<div style="display: flex; font-size: 12px">
      <span style="margin-right: 10px; color: white">Range:</span>
      <span style="color: #FDB022">${
        key.charAt(0).toUpperCase() + key.slice(1)
      } Range: ${(d[1] - d[0]).toFixed(2)}</span>
    </div>`
          )
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 20}px`);
      })
      .on('mousemove', (event) => {
        tooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 20}px`);
      })
      .on('mouseout', function () {
        // Restore all bars to original color
        // bars.attr('fill', (d) => color(d.key));

        // Hide tooltip
        tooltip.style('opacity', 0);
      });

    // X-axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      // .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    // Y-axis
    svg.append('g').call(d3.axisLeft(y));

    // Add legend
    const legendContainer = d3
      .select(`#${id}`)
      .insert('div', ':first-child')
      .style('display', 'flex')
      .style('flex-direction', 'row')
      .style('gap', '10px')
      .style('margin-bottom', '10px');

    const legend = legendContainer
      .selectAll('.legend')
      .data(keys.slice().reverse())
      .enter()
      .append('div')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('gap', '5px');

    legend
      .append('div')
      .style('width', '18px')
      .style('height', '18px')
      .style('background-color', (d) => color(d));

    legend
      .append('span')
      .style('color', 'white')
      .text((d) => d.charAt(0).toUpperCase() + d.slice(1) + ' Range');

    // Cleanup tooltip on unmount
    return () => {
      tooltip.remove();
    };
  };

  useEffect(() => {
    renderChart();
  }, [data]);

  return <div id={id} style={{ width: '100%' }}></div>;
};
