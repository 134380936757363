import countryConfig from './CountryBasedAppConfig.json';

export const PLATFORM_TYPES = ['Booking', 'Tripadvisor', 'Airbnb'];
export const SENTIMENT_TYPES = ['Positive', 'Negative'];
export const ESTABLISHMENT_TYPES = [
  'Accommodation',
  'Attraction',
  'Restaurant',
];
export const ASPECT_TYPES = [
  'Comfort',
  'Hospitality',
  'Cleanliness',
  'WiFi',
  'Safety',
  'Location',
  'Accessibility',
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const links = [
  {
    text: 'Intro',
    link: '/intro',
    icon: 'intro',
  },
  {
    text: 'Categories',
    link: '/categories',
    icon: 'categories',
  },
  {
    text: 'Regions',
    link: '/regions',
    icon: 'regions',
  },
  {
    text: 'Aspects',
    link: '/aspects',
    icon: 'aspects',
  },
  {
    text: 'Prices',
    link: '/prices',
    icon: 'prices',
  },
];

export const COUNTRY_NAME = countryConfig.countryName;
export const REGION_TYPES = countryConfig.regions;
export const SLIDES = countryConfig.mainPageSlides;
export const GEOJSON = countryConfig.geoJsonPath;
