import React, { useEffect, useState, useContext } from 'react';
import { useNavigation, Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import Context from '../../context/context';
import Navigation from '../../components/navigation/navigation';
import Filters from '../../components/filters/filters';
import DownloadStatisticalDataButton from '../../components/download-as-excel-btn/download-statistical-btn';
import DownloadCategoriesDataButton from '../../components/download-as-excel-btn/download-categories-data-btn';
import DownloadRegionDataButton from '../../components/download-as-excel-btn/download-region-data-btn';
import DownloadAspectsDataButton from '../../components/download-as-excel-btn/download-aspect-data-btn';
import DownloadPricesDataButton from '../../components/download-as-excel-btn/download-prices-data-btn';
import service from '../../service';
import LogoutButton from '../../components/logout/logout-btn';
import './root.css';

const { Header, Content, Footer, Sider } = Layout;

const siderStyle = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
  width: 150,
  background: 'transparent',
};
const layoutStyle = {
  position: 'relative',
  zIndex: 1,
  background: 'transparent',
};

const pathButtons = {
  '/intro': <DownloadStatisticalDataButton />,
  '/categories': <DownloadCategoriesDataButton />,
  '/regions': <DownloadRegionDataButton />,
  '/aspects': <DownloadAspectsDataButton />,
  '/prices': <DownloadPricesDataButton />,
};

function Root() {
  const {
    selectedPlatform,
    selectedCategory,
    selectedSubCategory,
    selectedDates,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
    updateFilteredEstablishments,
    updateNoPlatformFilteredReviews,
    updateActiveSubCategories,
    updateFilteredReviews,
    setEstablishmentTypes,
  } = useContext(Context);

  const [reviews, setReviews] = useState([]);
  const [establishments, setEstablishments] = useState([]);

  const location = useLocation();

  const navigation = useNavigation();
  const isNavigating = navigation.state === 'loading';

  function applyPlatformFilters(activeReviews) {
    if (selectedPlatform === null) return activeReviews;
    return activeReviews.filter((r) => {
      return selectedPlatform === r.data_platform;
    });
  }

  function applyNoPlatformFilters(activeReviews) {
    let filteredArray = activeReviews;

    if (selectedCategory !== null) {
      filteredArray = activeReviews.filter((r) => {
        return selectedCategory === r.establishment_type;
      });
    }
    if (selectedSubCategory.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        if (r.establishment_subcategory === null) return false;
        const subcategoryTypes = r.establishment_subcategory.split(',');
        return subcategoryTypes.some((sub) => {
          return selectedSubCategory.includes(sub);
        });
      });
    }
    if (selectedRegion.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedRegion.includes(r.district);
      });
    }
    if (selectedDates.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedDates[0] <= r.date && r.date <= selectedDates[1];
      });
    }
    if (selectedSentiment !== null) {
      filteredArray = filteredArray.filter((r) => {
        return r.aspectsentiment_set.some((as) => {
          return selectedSentiment === as.label;
        });
      });
    }
    if (selectedAspect.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return r.aspectsentiment_set.some((as) => {
          return selectedAspect.includes(as.aspect);
        });
      });
    }
    return filteredArray;
  }

  const renderButtons = () => {
    const currentPath = location.pathname;
    return pathButtons[currentPath] || '';
  };

  function applyEstablishmentTypeFilter(activeEstablishments) {
    let filteredArray = activeEstablishments;
    if (selectedCategory !== null) {
      filteredArray = activeEstablishments.filter((r) => {
        return selectedCategory === r.type;
      });
    }

    if (selectedSubCategory.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        if (r.subcategory === null) return false;
        const subcategoryTypes = r.subcategory.split(',');
        return subcategoryTypes.some((sub) => {
          return selectedSubCategory.includes(sub);
        });
      });
    }

    if (selectedRegion.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        return selectedRegion.includes(r.district);
      });
    }
    return filteredArray;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          reviews: fetchedReviews,
          establishments: fetchedEstablishments,
        } = await service.getAllData();
        setReviews(fetchedReviews);
        setEstablishments(fetchedEstablishments);
        const uniqueTypes = [
          ...new Set(fetchedReviews.map((review) => review.establishment_type)),
        ];
        setEstablishmentTypes(uniqueTypes);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [setEstablishmentTypes]);

  useEffect(() => {
    const noPlatformFilters = applyNoPlatformFilters(reviews);
    updateFilteredEstablishments(establishments);
    updateNoPlatformFilteredReviews(noPlatformFilters);
    updateFilteredReviews(applyPlatformFilters(noPlatformFilters));
  }, [
    reviews,
    selectedPlatform,
    selectedCategory,
    selectedSubCategory,
    selectedDates,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  ]);

  useEffect(() => {
    let activeSubCategories = reviews.reduce((acc, r) => {
      if (r.establishment_subcategory === null) return acc;
      const subcategoryTypes = r.establishment_subcategory.split(',');
      subcategoryTypes.forEach((sub) => {
        if (!acc.includes(sub)) {
          acc.push(sub);
        }
      });
      return acc;
    }, []);

    activeSubCategories = activeSubCategories.filter((r) => r !== null);
    updateActiveSubCategories(activeSubCategories);
  }, [reviews]);

  useEffect(() => {
    const establishmentTypeFilter =
      applyEstablishmentTypeFilter(establishments);
    updateFilteredEstablishments(establishmentTypeFilter);
  }, [establishments, selectedCategory, selectedSubCategory]);

  const username = localStorage.getItem('username');
  const isAuthenticated = !!localStorage.getItem('accessToken');
  const isHomePage = location.pathname === '/home';

  return (
    <div className="App">
      {isHomePage ? (
        <Layout hasSider style={layoutStyle}>
          <Layout
            style={{
              marginLeft: 50,
              marginRight: 50,
              background: 'transparent',
            }}
          >
            <Header
              className="root_header"
              style={{
                padding: 0,
                background: 'transparent',
              }}
            >
              <div className="header"></div>
            </Header>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Layout hasSider style={layoutStyle}>
          <Sider style={siderStyle}>{isAuthenticated && <Navigation />}</Sider>
          <Layout
            style={{
              marginLeft: 150,
              marginRight: 50,
              background: 'transparent',
            }}
          >
            <Header
              className="root_header"
              style={{
                padding: 0,
                background: 'transparent',
              }}
            >
              <div className="header"></div>
              {/* {isAuthenticated && username && location !=='/' ?<span>Welcome, {username}!</span> : ''} */}
              {isAuthenticated && <LogoutButton />}
              {renderButtons()}
              {isAuthenticated && <Filters />}
            </Header>
            <Content>
              {/* {isNavigating ? (
              <div className="loading-indicator">
                <div className="spinner"></div>
              </div>
            ) : ( */}
              <Outlet />
              {/* )} */}
            </Content>
            <Footer
              style={{
                background: 'transparent',
              }}
            ></Footer>
          </Layout>
        </Layout>
      )}
    </div>
  );
}

export default Root;
